<script setup>
import useOutboundOrders from '@/hooks/useOutboundOrders';

const { printDocs } = useOutboundOrders();

const props = defineProps({
  outboundOrder: {
    type: Object,
    required: false,
  },
});
const navItems = (id) => {
  return [
    { text: "Details", icon: "mdi-file", to: `/outbound-orders/${id}` },
    {
      text: "Documents",
      icon: "mdi-folder",
      to: `/outbound-orders/${id}/documents`,
    },
    {
      text: "History",
      icon: "mdi-history",
      to: `/outbound-orders/${id}/history`,
    },
    { text: "Billables", icon: "mdi-cash" },
    { 
      text: "Appointment", 
      icon: "mdi-calendar-clock" ,
      to: `/outbound-orders/${id}/appointments`,
    },
    {
      text: "Inventory Transactions",
      icon: "mdi-list-box",
      to: `/outbound-orders/${id}/inventory-transactions`,
    },
    {
      text: "Lp Transactions",
      icon: "mdi-list-box",
      to: `/outbound-orders/${id}/lp-transactions`,
    },
    {
      text: "Shipments",
      icon: "mdi-shipping-pallet",
      to: `/outbound-orders/${id}/shipments`,
    },
    {
      text: "Lots",
      icon: "mdi-tag-multiple",
      // to: `/outbound-orders/${id}/lots`,
    },
    {
      text: "Transport",
      icon: "mdi-truck-cargo-container",
      to: `/outbound-orders/${id}/transport`,
    },
  ];
};

const printItems = (id) => {
  return [
    { text: "Packing Slip", icon: "mdi-printer", click: () => printDocs(`/outbound_orders/${id}/pack_slip`) },
    { text: "Pick Slip", icon: "mdi-printer", click: () => printDocs(`/outbound_orders/${id}/pick_slip`) },
    { text: "Pallet Tags", icon: "mdi-printer", click: () => printDocs(`/outbound_orders/${id}/pallet_tags`) },
    { text: "Bill of Lading", icon: "mdi-printer", click: () => printDocs(`/outbound_orders/${id}/bol`) },
  ];
};

</script>

<template>
  <div class="d-flex ml-4">
    <div class="w-25">
      <v-card class="mx-auto pa-2 w-100">
        <div class="text-center" v-if="!outboundOrder">
          <v-progress-circular
            :width="8"
            :size="50"
            color="primary"
            class="mb-16"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-list v-if="props.outboundOrder">
          <v-list-subheader>OUTBOUND ORDER #OUT-{{ outboundOrder.id }}</v-list-subheader>

          <v-list-item
            v-if="outboundOrder"
            v-for="(item, i) in navItems(outboundOrder.id)"
            exact="true"
            :key="i"
            :value="item"
            color="primary"
            rounded="xl"
            :to="item.to"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card class="mx-auto pa-2 w-100 mt-4">
        <div class="text-center" v-if="!outboundOrder">
          <v-progress-circular
            :width="8"
            :size="50"
            color="primary"
            class="mb-16"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-list v-if="props.outboundOrder">
          <v-list-subheader>PRINT</v-list-subheader>

          <v-list-item
            v-if="outboundOrder"
            v-for="(item, i) in printItems(outboundOrder.id)"
            :key="i"
            :value="item"
            color="primary"
            rounded="xl"
            @click="item.click ? item.click() : null"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <div class="w-100">
      <slot />
    </div>
  </div>
</template>
